<template>
  <div class="brokerInfo">
    <van-sticky>
      <!-- 返回按钮 -->
      <div class="header">
        <div class="topBtn back" @click="$router.go(-1)">
          <van-icon name="arrow-left" size="25" color="white" />
        </div>
        <div class="info">
          <div class="info-header">
            <div class="name">{{ agent.name }}</div>
            <div class="attention" v-if="!following" @click="editUserCare">
              <van-icon name="plus"></van-icon>
              <span>关注</span>
            </div>
            <div class="attention" v-else @click="editUserCare">
              <span>已关注</span>
            </div>
          </div>
          <div class="sign" v-if="agent.introduce">{{ agent.introduce }}</div>
          <div class="sign" v-else>这个人很懒，什么都没有留下</div>
          <div class="assure" v-if="agent.payable">
            <div class="textImg"></div>
            <div class="text">
              <p>
                已缴纳保证金{{ agent.deposit }}元
              </p>
            </div>
          </div>
        </div>
        <ImgDecypt class="avatar" :src="agent.avatar" :round="true"></ImgDecypt>
      </div>
    </van-sticky>
    <div class="main">
      <van-pull-refresh v-model="refreshing" success-text="刷新成功" @refresh="onRefresh">
        <!-- <template #pulling>
          <div></div>
        </template>
        <template #loosing>
          <div></div>
        </template> -->
        <template #loading>
          <div></div>
        </template>
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          :offset="80"
          :immediate-check="false"
          @load="onLoad"
          v-if="!isNoData"
        >
          <div class="box">
            <div class="box-item" v-for="(item,index) in loufengData" :key="index" @click="jumpLouFengDetail(item.id)">
              <div class="infoBox">
              <div class="infobox-item payable" v-if="item.payable"></div>
              <div class="infobox-item top" v-if="item.topType === 9"></div>
              <div class="infobox-item boutique" v-if="item.topType === 8"></div>
                <div class="img">
                  <ImgDecypt :src="item.img" fill="cover"/>
                </div>
                <div class="title">
                  {{ item.title }}
                </div>
                <div class="info">
                  <span>{{ item.height }}cm.{{ item.age }}.{{ item.cup }}</span>
                </div>
                <div class="price">
                  <span>价格：{{ item.srvPrice }}</span>
                </div>
              </div>
            </div>
          </div>
        </van-list>
        <!-- 没有数据的时候展示 -->
        <NoData v-if="isNoData" class="noData"></NoData>
      </van-pull-refresh>
    </div>
    <!-- 整页加载 -->
    <van-overlay :show="firstLoading" z-index="101">
      <div class="wrapper" @click.stop>
        <van-loading type="spinner" color="#f21313">数据加载中...</van-loading>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { getLoufengAgentList, editUserCare } from '@/api/loufeng.js'
import NoData from "@/components/NoData";

export default {
  name: 'brokerInfo',
  components: {
    NoData
  },
  data() {
    return {
      loufengData: [],
      pageNum: 1,
      pageSize: 10,
      firstLoading: false,
      loading: false, //下滑loading
      finished: false, //数据是否加载完毕
      refreshing: false, //下拉下载loading
      isNoData: false, //数据是否为空
      agent: {}, //经纪人对象
      following: false // 是否已关注
    }
  },
  created() {
    this.getAgent();
    this.getLoufengList();
  },
  methods: {
    getAgent() {
      let tempAgent = this.$route.params.agent;
      if(!tempAgent) {
        this.agent =  JSON.parse(localStorage.getItem('agent'));
      } else {
        this.agent = tempAgent;
        localStorage.setItem('agent', JSON.stringify(this.$route.params.agent));
      }
      this.following = this.agent.following;
    },
    async getLoufengList() {
      let req = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        agentId: this.agent.account
      }
      let res = await this.$Api(getLoufengAgentList, req);
      this.loading = false;
      this.refreshing = false;
      this.firstLoading = false;
      if(res && res.code === 200) {
        let loufengData = res.data.list;
        this.loufengData = this.loufengData.concat(loufengData);
        if (loufengData.length === 0 && this.pageNum == 1) {
          this.isNoData = true;
          this.finished = true;
          return;
        }
        if (res.data.list.length < this.pageSize) {
          this.finished = true;
        }
      } else {
        this.$toast('获取数据异常')
      }
    },
    // 数据加载
    onLoad() {
      this.pageNum++;
      this.getLoufengList();
    },
    // 数据刷新
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      // 重新加载数据
      this.firstLoading = true;
      this.loufengData = [];
      this.pageNum = 1;
      this.getLoufengList();
    },
    // 去楼凤详情页
    jumpLouFengDetail(id) {
      this.$router.push({
        name: 'loufengDetail',
        params: {
          id: id,
          isFromBroker: true
        }
      })
    },
    // 关注与取消
    async editUserCare() {
      let req = {
        isCare: !this.following,
        userId: this.agent.id
      }
      let res = await this.$Api(editUserCare, req);
      if(res && res.code === 200) {
        if(this.following) {
          this.$toast('取消关注成功');
        } else {
          this.$toast('关注成功');
        }
        this.following = !this.following;
        this.agent.following = this.following;
        localStorage.setItem('agent', JSON.stringify(this.agent));
      } else {
        this.$toast('操作异常')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.brokerInfo {
  .header {
    height: 140px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: black;
    padding: 20px 20px 0 20px;
    background-image: url('../../../assets/png/personal-bg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    // 顶部按钮
    .topBtn {
      height: 40px;
      width: 40px;
      background-color: rgba(0, 0,0, 0.2);
      border-radius: 50%;
      position: fixed;
      z-index: 0;
      top: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    // 返回
    .back {
      left: 10px;
    }
    .info {
      color: white;
      .info-header {
        margin-top: 20px;
        height: 40px;
        width: 250px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .name {
          font-size: 16px;
        }
        .attention {
          height: 22px;
          width: 60px;
          color: white;
          border-radius: 15px;
          background-color: red;
          text-align: center;
          line-height: 22px;
        }
      }
      .sign {
        height: 40px;
        line-height: 20px;
        width: 250px;
        overflow:hidden;
        text-overflow:ellipsis;
        display:-webkit-box; 
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
      .assure {
        height: 30px;
        width: 100%;
        display: flex;
        align-items: center;
        margin-left: 5px;
        .textImg {
          height: 30px;
          width: 30px;
          background: url('../../../assets/png/save.png') center center;
          background-size: contain;
          background-repeat: no-repeat;
        }
        .text {
          height: 21px;
          width: 128px;
          border-radius: 10.5px 10.5px;
          background-color: rgb(255,243,226);
          line-height: 21px;
          text-align: center;
          color: rgb(210,36,36);
          margin-left: 5px;
          p {
            transform: scale(0.8, 0.8);
          }
        }
      }
    }
    .avatar {
      width: 64px;
      height: 64px;
    }
  }
  .main {
    padding: 10px 10px;
    .box {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .box-item {
        width: 49%;
        .infoBox {
          width: 100%;
          border-radius: 5px;
          box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.6);
          padding: 0 0;
          margin-bottom: 10px;
          position: relative;
          overflow: hidden;

          .img {
            width: 100%;
            text-align: center;
            height: 234px;

            /deep/ .van-image__img {
              border-radius: 5px 5px 0 0;
            }
          }

          .title {
            width: 150px;
            font-size: 13px;
            word-break: keep-all;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 4px 4px;
          }

          .info {
            width: 100%;
            font-size: 12px;
            color: rgba(0, 0, 0, 0.6);
            margin: 4px 4px;
          }

          .price {
            width: 100%;
            font-size: 12px;
            color: red;
            margin-top: 4px;
            margin-left: 4px;
            height: 22px;
            word-break: keep-all;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .infobox-item {
            position: absolute;
            z-index: 22;
            color: white;
            font-size: 13px;
            line-height: 24px;
            text-align: center;
            font-weight: 800;
          }

          // 可赔付
          .payable {
            left: 0px;
            top: 0px;
            height: 24px;
            width: 54px;
            background: url('../../../assets/png/payable.png') center center;
            background-repeat: no-repeat;
            background-size: cover;
            border-radius: 5px 0 5px 0;
          }

          // 置顶
          .top {
            right: -4px;
            top: 0;
            height: 60px;
            width: 60px;
            border-radius: 5px 5px 0 5px;
            background: url('../../../assets/png/top.png') center center;
            background-size: cover;
            background-repeat: no-repeat;
          }

          // 精品
          .boutique {
            right: -2px;
            top: 0;
            height: 60px;
            width: 60px;
            border-radius: 5px 5px 0 5px;
            background: url('../../../assets/png/boutique.png') center center;
            background-size: cover;
            background-repeat: no-repeat;
          }
        }
      }
    }
    .noData {
      height: calc(100vh - 150px);
    }
    // 底部没有更多了
    /deep/ .van-list__finished-text {
      line-height: 30px;
      margin-bottom: 10px;
    }
    // 下拉loading
    /deep/ .van-loading {
      height: 100px;
    }
  }
  // 整页加载
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: white;
  }
  /deep/ .van-loading--spinner {
    margin-top: unset;
  }
}
.all-content {
  height: unset;
}
</style>
